<template>
    <el-table v-if="tableData.length > 0" :data="tableData" style="width: 100%">
        <el-table-column width="55">
            <template slot-scope="scope">
                <el-popover
                    v-if="scope.row.image_src"
                    placement="top-start"
                    width="250"
                    trigger="hover"
                >
                    <img :src="scope.row.image_src" class="h-56 w-56 rounded-full">
                    <img slot="reference" :src="scope.row.image_src" class="h-8 w-8 rounded-full">
                </el-popover>
            </template>
        </el-table-column>
        <el-table-column prop="name" width="100" :label="$t('clients.number')" sortable :sort-method="nameSort" />
        <el-table-column prop="description" :label="$t('clients.description')" sortable :show-overflow-tooltip="true" />
        <el-table-column prop="address" :label="$t('clients.address')" sortable :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <router-link
                    :to="`/clients/${clientUuid}/address/${scope.row.address.uuid}`"
                    class="hover:text-red-500 hover:font-semibold"
                >
                    {{ scope.row.address.street }}, {{ scope.row.address.post_code }} {{ scope.row.address.city }}
                </router-link>
            </template>
        </el-table-column>
        <el-table-column prop="holder" :label="$t('clients.holds')" sortable :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <router-link
                    v-if="scope.row.holder_uuid"
                    :to="`/employees/${scope.row.holder_uuid}/information`"
                    class="hover:text-red-500 hover:font-semibold"
                >
                    {{ currentHolderFormatter(scope.row.holder) }}
                </router-link>
                <p v-else>
                    {{ currentHolderFormatter(scope.row.holder) }}
                </p>
            </template>
        </el-table-column>
        <el-table-column width="35">
            <template slot-scope="scope">
                <el-tooltip
                    v-if="scope.row.replacement"
                    :enterable="false"
                    :openDelay="500"
                    class="item"
                    effect="dark"
                    :content="scope.row.replacement"
                    placement="left"
                >
                    <fa-icon :icon="['far', 'sync']" />
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column width="130">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('clients.history')" placement="left">
                        <el-button type="primary" size="mini" @click="handleKeyHistory(scope.row.uuid)">
                            <fa-icon :icon="['far', 'history']" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('clients.change_holder')" placement="top">
                        <el-button type="primary" size="mini" :disabled="!userCan('update keys')" @click="handleEditHolder(scope.row.uuid)">
                            <fa-icon :icon="['far', 'sync']" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.edit')" placement="top">
                        <el-button type="primary" size="mini" :disabled="!userCan('update keys')" @click="handleEdit(scope.row.uuid)">
                            <fa-icon :icon="['far', 'edit']" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="top">
                        <el-button type="danger" size="mini" :disabled="!userCan('delete keys')" @click="handleDelete(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'trash']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    props: {
        tableData: {
            type:    Array,
            default: () => [],
        },
    },

    data() {
        return {
            clientUuid: this.$route.params.uuid,
        };
    },

    methods: {
        currentHolderFormatter(holder) {
            if (holder === 'office' || holder === 'client') {
                return this.$t(`clients.at_${holder}`);
            }
            return holder;
        },

        handleKeyHistory(uuid) {
            this.$emit('handleKeyHistory', uuid);
        },

        handleEditHolder(uuid) {
            this.$emit('handleEditHolder', uuid);
        },

        handleEdit(uuid) {
            this.$emit('handleEdit', uuid);
        },

        handleDelete(uuid) {
            this.$emit('handleDelete', uuid);
        },

        nameSort(a, b) {
            return a.name - b.name;
        },
    },
};
</script>
